<template>
  <Popover v-model:open="show">
    <PopoverTrigger as-child>
      <div
        class="flex w-full justify-between items-center gap-4 h-10 hover:bg-gray-50 p-2 dotted rounded-md cursor-pointer border"
      >
        <span class="truncate text-sm">{{ buttonTitle() }}</span>
        <div><ChevronDownIcon class="w-4 h-4 opacity-50" /></div>
      </div>
    </PopoverTrigger>
    <PopoverContent class="p-1 overflow-auto max-h-[400px]" align="start">
      <div class="pl-1">
        <ZLabel class="flex gap-2 items-center p-1 py-2 hover:bg-accent rounded-md cursor-pointer" :class="{'opacity-30': selected.length == 0 || selected == '', 'pointer-events-none': selected.length == 0 || selected == ''}" @click="unselectAll()">
          <MinusCircleIcon class="icon sm"/>
          {{ $t('common.unselect') }}
        </ZLabel>
      </div>
      <Separator/>
      <div v-for="(item, index) in metaTree" :key="index" class="pl-1">
        <ZLabel class="flex items-center px-1 hover:bg-accent rounded-md cursor-pointer">
          <Checkbox id="consent" :checked="isChecked(item.value)" @update:checked="selectItem(item.value)" class="rounded-full border-gray-300 bg-white">
            <div class="w-full h-full bg-white rounded-full flex items-center justify-center">
              <div class="w-[6px] h-[6px] bg-primary rounded-full"></div>
            </div>
          </Checkbox>
          <div class="ml-2 text-sm">
            <span class="flex items-center">
              <span v-for="indent in item.path" class="inline-block flex items-center">
                <span v-if="indent == 1" class="inline-block w-[15px] h-[28px] border-r-2"></span>
                <span v-if="indent == 2" class="inline-block w-[15px] h-[28px]">
                  <span class="inline-block w-[15px] h-[14px] border-r-2"></span>
                </span>
                <span v-if="indent == 0" class="inline-block w-[15px] h-[28px]"></span>
              </span>
              <span class="inline-block w-[6px] border-b-2 mr-1"></span>
              {{ item.meta.label }}
            </span>
          </div>
        </ZLabel>
      </div>
    </PopoverContent>
  </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/components/ui/popover';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { ZLabel } from '@shadcn/components/ui/label';
import { ChevronDownIcon, MinusCircleIcon } from '@heroicons/vue/24/outline';
import {ZSelectContent} from "@shadcn/components/ui/select";
import {Separator} from "@shadcn/components/ui/separator";

export default defineComponent({
  components: {
    Separator,
    ZSelectContent,
    ZLabel,
    ChevronDownIcon, MinusCircleIcon,
    Checkbox,
    Popover,
    PopoverContent,
    PopoverTrigger,
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      required: false,
      default: '',
    },
    tree: {
      type: Map,
      required: false,
      default: [],
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    shorten: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      show: false as boolean,
      selected: this.modelValue,
    };
  },
  computed: {
    metaTree() {
      let mtree = [] as Array<Object>;
      for (let [value, meta] of this.tree) {
        mtree.push({ value: value, meta: meta });
      }
      mtree.reverse();
      let path = [];
      for (let leaf in mtree) {
        let node = mtree[leaf].meta;
        for (let d = 0; d < node.depth; d++) {
          if (path.length < d) path.push(0);
          else if (path[d] == 2) path[d] = 1;
        }
        if (path.length < node.depth) path.push(2);
        else if (path.length > node.depth) {
          path.length = node.depth;
        }
        if (path.length == node.depth && path[node.depth - 1] == 0) path[node.depth - 1] = 2;
        mtree[leaf].path = [...path];
      }
      mtree.reverse();
      return mtree;
    },
  },
  watch: {
    modelValue() {
      this.selected = this.modelValue;
    },
    selected() {
      this.$emit('update:modelValue', this.selected);
    },
  },
  methods: {

    buttonTitle() {
      let list = [] as any;
      if (Array.isArray(this.selected)) {
        list = this.selected;
      }
      let selected = list.length ? list : [];
      const values = [] as string[];
      if (selected) {
        for (let key in selected as any) {
          values[key] = this.tree.get(selected[key])?.label;
        }
      }
      if (values.length == 0) {
        return this.placeholder;
      } else if (values.length == 1) {
        return values.join(', ');
      } else if (this.shorten) {
        return this.$t('common.selected') + values.length;
      } else {
        return values.join(', ');
      }

    },

    selectItem(value: any) {
      if (!Array.isArray(this.selected)) {
        this.selected = [];
      }
      var index = this.selected.indexOf(value);
      if (index == -1) {
        this.selected.push(value);
      } else {
        this.selected.splice(index, 1);
        if (this.selected.length == 0) {
          this.selected = [];
        }
      }
      this.$emit('update:modelValue', this.selected);
    },

    isChecked(value: any) {
      if (typeof this.selected == 'object') return this.selected?.includes(value);
    },

    unselectAll() {
      this.selected = [];
    }
  },
});
</script>
