import { defineStore } from 'pinia';
import { KeyValue } from '@/types/Common';
import useUserStore from "@/stores/auth";

const rememberStore = defineStore('rememberStore', {
  state: () => ({
    memories: {} as KeyValue,
  }),

  actions: {
    memorize(key: string, reminiscence: any) {
      const { chosenBranch } = useUserStore();

      this.memories[chosenBranch + key] = JSON.stringify(reminiscence);
      localStorage.setItem('memorize.' + chosenBranch + key, this.memories[chosenBranch + key]);
    },

    forget(key) {
      const { chosenBranch } = useUserStore();

      if (this.memories[chosenBranch + key]) {
        delete this.memories[chosenBranch + key];
        localStorage.setItem('memorize.' + chosenBranch + key, this.memories[chosenBranch + key]);
        return true;
      }

      return false;
    },

    remind(key: string, defaultVal: any) {
      const { chosenBranch } = useUserStore();

      if (this.memories[chosenBranch + key]) {
        return JSON.parse(this.memories[chosenBranch + key]);
      } else {
        let lastSessionValue = localStorage.getItem('memorize.' + chosenBranch + key);
        try {
          if (lastSessionValue) {
            this.memories[chosenBranch + key] = JSON.parse(lastSessionValue);
            return JSON.parse(lastSessionValue);
          }
        } catch(e:any) {
          return defaultVal;
        }
      }
      return defaultVal;
    },
  },
});
export default rememberStore;
