<template>
  <DataLoader ref="loader">
    <div class="py-4">
      <IconAlert v-if="stage !== 'default'" type="info">
        <div class="flex md:flex-row md:justify-between flex-col gap-4 md:items-center">
          <div v-if="stage == 'transfer'">
            <span>{{ $t('asset.label.transfer_assets_info') }}</span>
          </div>
          <div v-if="stage == 'tagging'">
            <span>{{ $t('asset.label.tagging_info') }}</span>
          </div>
          <div v-if="stage == 'update_status'">
            <span>{{ $t('asset.label.update_status_info') }}</span>
          </div>
          <div class="flex flex-col md:flex-row gap-3">
            <ZButton :disabled="!selectedAssets.length" class="flex" @click="openDialog">
              <span>{{ $t('common.submit') }}</span>
            </ZButton>
            <ZButton variant="destructive" class="flex" @click="refresh(false)">
              <span>{{ $t('common.cancel') }}</span>
            </ZButton>
          </div>
        </div>
      </IconAlert>
      <div class="grid sm:grid-cols-2 lg:grid-cols-1 lg:flex sm:gap-4 mt-5">
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.asset_search') }}</ZLabel>
          <ZInput v-model="filters.search" type="search" :placeholder="$t('asset.label.asset_search_placeholder')"/>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.filter_category') }}</ZLabel>
          <MultiselectTree
            v-model="filters.categories"
            :tree="categoriesTree"
            shorten
            :placeholder="$t('common.all')"
          />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.filter_tag') }}</ZLabel>
          <SelectMultipleField v-model="filters.tags" :placeholder="$t('asset.label.all_tags')" :options="tags"/>
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.filter_status') }}</ZLabel>
          <SelectMultipleField
            v-model="filters.status"
            :options="assetStatuses"
            :translate="'asset.states.'"
            shorten
            :placeholder="$t('common.all')"
          />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel for="technical_condition">
            {{ $t('asset.label.technical_condition') }}
          </ZLabel>
          <SelectMultipleField
            v-model="filters.technical_conditions"
            :translate="'asset.technical_conditions.'"
            :placeholder="$t('asset.label.all_technical_conditions')"
            :options="assetTechnicalConditions"
          />
        </div>
        <div class="w-full grid gap-2 mb-4">
          <ZLabel>{{ $t('contracts.label.sort') }}</ZLabel>
          <SelectField v-model="filters.sort" :options="asset_sorting" unselectable/>
        </div>
        <div class="flex flex-col gap-2 mb-4">
          <ZLabel>{{ $t('asset.label.reset_filter') }}</ZLabel>
          <ZButton variant="secondary" @click="resetFilters"><TrashIcon class="icon sm"></TrashIcon>{{ $t('asset.command.reset_filter') }}</ZButton>
        </div>
      </div>
    </div>
    <Paginator page-size :data="assetDataSet"/>
    <div v-if="stage !== 'default'" class="mt-2 mb-4">
      <ZLabel class="ml-[1rem] cursor-pointer flex">
        <Checkbox id="'checkAll" :checked="isCheckedAll" class="h-6 w-6" @click="checkAll"/>
        <span class="ml-3 my-auto">{{ $t('asset.label.check_all') }}</span>
      </ZLabel>
    </div>
    <div class="border rounded">
      <ZTable class="w-full">
        <ZTableHeader>
          <ZTableRow>
            <ZTableHead v-if="stage !== 'default'"></ZTableHead>
            <ZTableHead class="text-right">{{ $t('asset.label.status') }} / {{
                $t('asset.label.technical_condition')
              }}
            </ZTableHead>
            <ZTableHead></ZTableHead>
            <ZTableHead>{{ $t('asset.label.category') }}</ZTableHead>
            <ZTableHead>{{ $t('asset.label.manufacturer') }}</ZTableHead>
            <ZTableHead>{{ $t('asset.label.asset_model') }}</ZTableHead>
            <ZTableHead>{{ $t('asset.label.location') }}</ZTableHead>
            <ZTableHead>{{ $t('asset.label.inventory_number') }} / {{ $t('asset.label.tags') }}</ZTableHead>
          </ZTableRow>
        </ZTableHeader>
        <ZTableBody>
          <ZTableRow
            v-for="(item, index) in assetDataSet.data"
            :key="index"
            :class="[
              'cursor-pointer',
              assetInArray(selectedAssets, item) ? 'bg-blue-100' : '',
              (stage == 'transfer' || stage == 'update_status') && item.has_pending_contracts ? 'cursor-not-allowed opacity-30' : '',
            ]"
            @click="
              stage == 'default'
              ? details(item.id ?? 0)
              : toggleSelected(item.id ?? -1, !item.has_pending_contracts ?? false)
            "
          >
            <ZTableCell v-if="stage !== 'default'">
              <Checkbox
                :id="'checked_' + item.id"
                class="h-6 w-6"
                :checked="selectedAssets.some(asset => asset.id === item.id)"
                :disabled="stage !== 'tagging' && item.has_pending_contracts"
              />
            </ZTableCell>
            <ZTableCell class="text-right">
              <PrettyStatus :status="item.status!" :translate="'asset.states.'"/>
              / {{ $t('asset.technical_conditions.' + item.technical_condition) }}
            </ZTableCell>
            <ZTableCell>
              <img
                v-if="item.thumb"
                class="w-[50px] h-[50px] min-w-[50px] object-cover rounded-xl"
                :src="item.thumb"
                alt="primaryImage"
              />
            </ZTableCell>
            <ZTableCell>{{ item.category ? item.category.name : $t('asset.label.lack') }}</ZTableCell>
            <ZTableCell>
              {{ item.asset_model?.manufacturer }}
            </ZTableCell>
            <ZTableCell>
              {{ item.asset_model?.code }}
            </ZTableCell>
            <ZTableCell>
              <small>{{ item.branch?.path }}</small><br>
              {{ item.branch?.name }}{{ item.location ? ' - ' + item.location : '' }}
            </ZTableCell>
            <ZTableCell class="md:max-w-[20vw]">
              {{ item.asset_number }}
              <div class="mt-2">
                <span v-for="(tag, idx) in item.tags.slice(0, 3)" :key="idx" class="inline-block">
                  <TagLabel class="m-1 w-auto" :name="tag.name" :color="tag.color"/>
                </span>
                <span v-if="item.tags.length > 3" class="font-medium ml-1">(3+)</span>
              </div>
            </ZTableCell>
          </ZTableRow>
        </ZTableBody>
      </ZTable>
      <div v-if="assetDataSet.data?.length == 0" class="p-8 text-center flex flex-col gap-2 items-center">
        <ExclamationTriangleIcon class="icon lg"/>
        {{ $t('asset.message.data_not_found') }}
      </div>
    </div>
    <Paginator page-size :data="assetDataSet"/>
    <router-view></router-view>
    <TransferAssetsDialog
      v-if="isTransferDialogOpen"
      :assets="selectedAssets"
      @transfer-dialog-closed="isTransferDialogOpen = false"
      @saved="refresh(true)"
    >
    </TransferAssetsDialog>
    <TaggingAssetDialog
      v-if="isTaggingDialogOpen"
      :assets="selectedAssets"
      @tagging-dialog-closed="isTaggingDialogOpen = false"
      @saved="refresh(true)"
    >
    </TaggingAssetDialog>
    <UpdateAssetStatusesDialog
      v-if="isStatusUpdateDialogOpen"
      :assets="selectedAssets"
      @update-statuses-dialog-closed="isStatusUpdateDialogOpen = false"
      @saved="refresh(true)"
    >
    </UpdateAssetStatusesDialog>
    <MountedTeleport to="#toolbox">
      <div v-if="access.allowed(SYMBOL.PERMISSION.BRANCH_MANAGE, true)" class="flex flex-col lg:flex-row gap-3">

        <DropdownMenu>
          <DropdownMenuTrigger as-child>
            <ZButton class="w-full">
              <span class="ml-2">
                    {{ $t('asset.command.global_operations') }}
                  </span>
              <ChevronDownIcon class="icon xs ml-2"/>
            </ZButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent class="w-56">
            <DropdownMenuGroup>
              <DropdownMenuItem @click="stage = 'update_status'">
                <span>{{ $t('asset.command.status_update') }}</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator/>
              <DropdownMenuItem @click="stage = 'transfer'">
                <span>{{ $t('asset.command.asset_transfer') }}</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator/>
              <DropdownMenuItem @click="stage = 'tagging'">
                <span>{{ $t('asset.command.tagging') }}</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>


        <ZButton
          v-if="access.operator()"
          class="flex gap-2"
          :disabled="stage != 'default'"
          @click="$router.push({ name: ROUTE.IMPORT_ASSETS })"
        >
          <span>{{ $t('asset.command.import_assets') }}</span>
        </ZButton>
        <ZButton
          class="flex gap-2"
          :disabled="stage != 'default' || !access.hasBranchSelected()"
          @click="$router.push({ name: ROUTE.ASSET_ADD })"
        >
          <span class="block">{{ $t('asset.command.add_asset') }}</span>
          <PlusCircleIcon class="icon md"/>
        </ZButton>
      </div>
    </MountedTeleport>
  </DataLoader>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {ZButton} from '@shadcn/components/ui/button';
import MountedTeleport from '@ui/MountedTeleport.vue';
import {ExclamationTriangleIcon, PlusCircleIcon, TrashIcon} from '@heroicons/vue/24/outline';
import {ZLabel} from '@shadcn/components/ui/label';
import {ZInput} from '@shadcn/components/ui/input';
import {ZTable, ZTableBody, ZTableCell, ZTableHead, ZTableHeader, ZTableRow} from '@shadcn/components/ui/table';
import Paginator from '@ui/Paginator.vue';
import {DataSet} from '@/utils/DataSet';
import AssetRequestData = App.Data.AssetRequestData;
import {getAssets} from '@/api/asset';
import {ROUTE} from '@/router/routeNames';
import {getCompactCategories} from '@/api/category';
import CategoryCompactData = App.Data.CategoryCompactData;
import MinimalAssetData = App.Data.MinimalAssetData;
import IconAlert from '@ui/IconAlert.vue';
import {Checkbox} from '@shadcn/components/ui/checkbox';
import TransferAssetsDialog from '@views/Assets/TransferAssetsDialog.vue';
import TaggingAssetDialog from '@views/Assets/TaggingAssetDialog.vue';
import symbolsStore from '@/stores/symbols';
import {KeyValue} from '@/types/Common';
import UpdateAssetStatusesDialog from '@views/Assets/UpdateAssetStatusesDialog.vue';
import PrettyStatus from '@ui/PrettyStatus.vue';
import SelectField from '@ui/SelectField.vue';
import {getTagsCompact} from '@/api';
import TagsCompactData = App.Data.TagsCompactData;
import SelectMultipleField from '@ui/SelectMultipleField.vue';
import useAccessStore from '@/stores/access';
import DataLoader from '@ui/DataLoader.vue';
import MultiselectTree from '@ui/MultiselectTree.vue';
import TagLabel from '@ui/Tags/TagLabel.vue';
import {ChevronDownIcon} from "@modules/@heroicons/vue/24/outline";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import rememberStore from "@/stores/remember";
import Action from "@ui/Action.vue";

interface filtersInterface {
  categories?: number[];
  search: string;
  page: number;
  page_size: number;
  status?: string;
  tags?: number[];
  technical_condition?: string[];
}

export default defineComponent({
  components: {
    Action,
    DropdownMenu,
    ChevronDownIcon,
    TagLabel,
    DataLoader,
    MultiselectTree,
    SelectField,
    PrettyStatus,
    UpdateAssetStatusesDialog,
    SelectMultipleField,
    TransferAssetsDialog,
    Checkbox,
    IconAlert,
    ZTableHead,
    ZTableBody,
    ZTable,
    Paginator,
    ZTableHeader,
    ZTableRow,
    TaggingAssetDialog,
    ZTableCell,
    ExclamationTriangleIcon,
    PlusCircleIcon,
    TrashIcon,
    MountedTeleport,
    ZButton,
    ZLabel,
    ZInput,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  },

  data() {
    return {
      assetDataSet: {} as DataSet<MinimalAssetData, AssetRequestData>,
      asset_sorting: {} as KeyValue,
      categories: [] as CategoryCompactData[],
      assetStatuses: {} as KeyValue,
      assetTechnicalConditions: {} as KeyValue,
      stage: 'default',
      selectedAssets: [] as MinimalAssetData[],
      isTransferDialogOpen: false,
      isTaggingDialogOpen: false,
      loader: null as any,
      isStatusUpdateDialogOpen: false,
      isCheckedAll: false,
      tags: {},
      resetPages: true,
      filters: rememberStore().remind('asset.filters', {
        search: '',
        page: 1,
        page_size: 15,
        status: ['draft', 'in_use', 'in_operation', 'in_storage', 'in_maintenance', 'missing'],
        categories: [],
        tags: [],
        technical_conditions: [],
        sort: '',
      }),
    };
  },

  computed: {
    access: () => useAccessStore(),

    categoriesTree() {
      let tree = new Map<string, object>();

      this.categories.forEach(category => {
        const treeKey = category.id!.toString();
        tree.set(treeKey, {depth: category.depth, label: category.name});
      });

      return tree;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        const finalFilters: filtersInterface = {
          ...this.filters,
          categories: this.filters!.categories,
          tags: this.filters!.tags,
        };
        if (this.filters.status === 'all') {
          delete finalFilters['status'];
        }
        if (this.filters.technical_conditions.length == 0) {
          delete finalFilters['technical_condition'];
        }
        if (this.filters.categories == 0) {
          delete finalFilters['categories'];
        }
        if (this.resetPages) this.assetDataSet.setParams(finalFilters, true).resetToFirstPage();
        else this.assetDataSet.setParams(finalFilters, true).load(this.filters.page ?? 1);

        this.resetPages = true;
      },
    },

    stage() {
      this.selectedAssets = [];
      this.isCheckedAll = false;
    },

    $route(to: any) {
      if (to.name == ROUTE.ASSET_LIST) {
        this.resetPages = false;
        this.filters = rememberStore().remind('asset.filters');
      }
    },
  },

  async mounted() {
    this.loader = (this.$refs.loader as InstanceType<typeof DataLoader>).loader;
    const symbols = symbolsStore();
    this.assetStatuses = await symbols.get('asset_statuses');
    this.assetTechnicalConditions = await symbols.get('asset_technical_conditions');

    await this.fetchData();
    await this.getSorting();
  },

  methods: {
    async getCategories() {
      const categoriesResponse = await getCompactCategories().execute();
      this.categories = Array.isArray(categoriesResponse.data) ? categoriesResponse.data : [categoriesResponse.data];
    },

    openDialog() {
      if (this.stage === 'tagging') this.isTaggingDialogOpen = true;
      if (this.stage === 'update_status') this.isStatusUpdateDialogOpen = true;
      if (this.stage === 'transfer') this.isTransferDialogOpen = true;
    },

    toggleSelected(id: number, can_be_transferred: boolean) {
      if (!this.assetDataSet.data || (this.stage !== 'tagging' && !can_be_transferred)) return;

      const indexOfAsset = this.assetDataSet.data.findIndex(obj => obj.id === id);
      if (indexOfAsset != -1) {
        if (this.selectedAssets.findIndex(obj => obj.id === id) != -1) {
          this.selectedAssets = this.selectedAssets.filter(
            asset => this.assetDataSet.data && asset.id != this.assetDataSet.data[indexOfAsset].id,
          );
        } else {
          this.selectedAssets.push(this.assetDataSet.data[indexOfAsset]);
        }
      }

      this.isCheckedAll = this.allAssetsSelected();
    },

    async fetchData() {
      try {
        const finalFilters: filtersInterface = {
          ...this.filters,
          category: parseInt(this.filters!.category!, 10),
        };
        if (this.filters.status === 'all') {
          delete finalFilters['status'];
        }
        if (this.filters.technical_condition === 'all') {
          delete finalFilters['technical_condition'];
        }
        if (this.filters.category === '0') {
          delete finalFilters['category'];
        }

        if (!this.filters.tags.length) {
          delete finalFilters['tags'];
        }

        this.assetDataSet = new DataSet<MinimalAssetData, AssetRequestData>(
          (query: string) => getAssets(finalFilters).withLoader(this.loader).execute(query),
          () => {
            this.isCheckedAll = this.allAssetsSelected();
            rememberStore().memorize('asset.filters', {...this.filters, page: this.assetDataSet.meta?.current_page, page_size: this.assetDataSet.meta?.per_page})
          }
        ).setParams(finalFilters);

        await this.assetDataSet.load(this.filters.page ?? 1);
        await this.getCategories();

        const tagsResponse = await getTagsCompact().execute();
        this.tags = tagsResponse.data.reduce((accumulator: KeyValue, value: TagsCompactData) => {
          return {...accumulator, [value.id]: value.name};
        }, {});
      } catch (e) {
        rememberStore().forget('asset.filters');
      }
    },

    async getSorting() {
      const symbols = symbolsStore();
      const sorting = await symbols.get('asset_sorting');
      for (const [key, value] of Object.entries(sorting)) {
        this.asset_sorting[key] = this.$t(value);
      }
    },

    refresh(withFetch: boolean) {
      this.stage = 'default';
      this.selectedAssets = [];
      if (withFetch) this.fetchData();
    },

    details(id: number) {
      this.$router.push({name: ROUTE.ASSET_DETAILS, params: {assetId: id}});
    },

    checkAll() {
      if (!this.assetDataSet.data) return;

      if (this.isCheckedAll) {
        let toBeRemoved = [];
        for (let no in this.assetDataSet.data) {
          let asset = this.assetDataSet.data[no];
          if (this.stage != 'tagging' && asset.has_pending_contracts) continue;
          if (this.assetInArray(this.selectedAssets, asset)) toBeRemoved.push(asset);
        }
        for(let asset of toBeRemoved) {
          let removeIndex = this.selectedAssets.findIndex(obj => obj.id === asset.id);
          if (removeIndex != -1) this.selectedAssets.splice(removeIndex, 1);
        }
        this.isCheckedAll = false;
        return;
      } else {
        for (let asset of this.assetDataSet.data) {
          if (this.stage != 'tagging' && asset.has_pending_contracts) continue;
          if (!this.assetInArray(this.selectedAssets, asset)) this.selectedAssets.push(asset);
        }
        this.isCheckedAll = true;
      }
    },

    assetInArray(array: any, asset: any) {
      for(let item of array) {
        if (item.id == asset.id) return true;
      }

      return false;
    },

    allAssetsSelected() {
      let result = true;
      for (let no in this.assetDataSet.data) {
        let asset = this.assetDataSet.data[no];
        if (this.stage != 'tagging' && asset.has_pending_contracts) continue;
        if (!this.assetInArray(this.selectedAssets, asset)) result = false;
      }
      return result;
    },

    resetFilters() {
      this.filters = {
        search: '',
        page: 1,
        page_size: 15,
        status: ['draft', 'in_use', 'in_operation', 'in_storage', 'in_maintenance', 'missing'],
        categories: [],
        tags: [],
        technical_conditions: [],
        sort: '',
      };
    }
  },
});
</script>
